import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function OfficeCleaning() {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const backtopRef = useRef(null);
  const serviceRef = useRef(null);
  const aboutRef = useRef(null);
  const wecareRef = useRef(null);
  const contactRef = useRef(null);

  const handleBackTopClick = () => {
    if (backtopRef.current) {
      backtopRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleServiceClick = () => {
    if (serviceRef.current) {
      serviceRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleAboutClick = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleWeCareClick = () => {
    if (wecareRef.current) {
      wecareRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleContactClick = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <header
        className="w-100 float-left header-con bg-fafafa"
        id="back-top"
        ref={backtopRef}
      >
        <div className="wrapper">
          <nav className="navbar navbar-expand-lg navbar-dark px-0">
            <Link className="navbar-brand d-lg-none" to="/">
              <img src="images/mobile-logo.png" alt="mobile-logo" />
            </Link>

            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarToggle"
              aria-controls="navbarToggle"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarToggle"
            >
              <Link className="navbar-brand d-none d-lg-block" to="/">
                <div className="mb-0">
                  <img
                    src="images/web-logo.png"
                    alt="logo-img"
                    className="logo-size"
                  />
                </div>
              </Link>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    activeClassName="active"
                    className="nav-link p-0"
                    to="/"
                    onClick={handleBackTopClick}
                  >
                    HOME
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link p-0"
                    to="/"
                    onClick={handleAboutClick}
                  >
                    ABOUT US{" "}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link p-0"
                    to="/"
                    onClick={handleServiceClick}
                  >
                    Services{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link p-0"
                    to="/"
                    onClick={handleWeCareClick}
                  >
                    We Care{" "}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link p-0"
                    to="/"
                    onClick={handleContactClick}
                  >
                    Contact{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      {/* CLEAN SECTION */}
      <section className="w-100 float-left project-con buttom-con">
        <div className="wrapper2">
          <div className="generic-box service-box position-relative">
            <div
              className="generic-box-img"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <div className="generic-box-img1 position-relative">
                <figure className="mb-0">
                  <img src="images/Office-cleaning1.jpg" alt="clean-img" />
                </figure>
              </div>
            </div>
            <div className="generic-box-content position-relative">
              <h2 data-aos="fade-up" data-aos-duration={600}>
                Office Cleaning
              </h2>
              <p data-aos="fade-up" data-aos-duration={600}>
                We specialize in the art of office cleaning. With a commitment
                to creating pristine and inspiring workspaces, we are your
                trusted partner in maintaining a hygienic and organized
                environment for your business. Our dedicated team of
                professionals ensures that your office space not only sparkles
                but also fosters productivity. Experience the difference with
                our tailored office cleaning solutions, designed to meet your
                unique needs. Let us transform your workplace into a shining
                beacon of professionalism and cleanliness.
              </p>
              <span
                className="job-title"
                data-aos="fade-up"
                data-aos-duration={600}
              >
                Inclusions :
              </span>
              <div className="generic-list2">
                <ul className="list-unstyled mb-0">
                  <li data-aos="fade-up" data-aos-duration={600}>
                    Cleaning and sanitizing desks, work surfaces, and cubicles.
                  </li>
                  <li data-aos="fade-up" data-aos-duration={600}>
                    Disinfection of high traffic and high touch surfaces
                  </li>
                  <li data-aos="fade-up" data-aos-duration={600}>
                    Dusting, vacuuming, and cleaning tables, chairs, and other
                    furniture.
                  </li>
                  <li data-aos="fade-up" data-aos-duration={600}>
                    Emptying and replacing trash bags in designated containers.
                  </li>
                  <li data-aos="fade-up" data-aos-duration={600}>
                    Sanitizing frequently touched surfaces like doorknobs, light
                    switches, and handrails.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* CLEAN SECTION */}
      <footer className="w-100 float-left footer-con position-relative">
        <div className="wrapper">
          <div className="footer-box position-relative">
            <div className="site-map">
              <h4>About Us</h4>
              <p className="mb-0">
                We are dedicated to delivering top-quality cleaning services,
                ensuring that every space we touch is thoroughly cleaned,
                sanitized, and revitalized.
              </p>
            </div>
            <div className="site-map">
              <h4>Quick Links</h4>
              <ul className="list-unstyled mb-0">
                <li>
                  <Link onClick={handleBackTopClick}>Home</Link>
                </li>
                <li>
                  <Link onClick={handleAboutClick}>About us</Link>
                </li>
                <li>
                  <Link onClick={handleServiceClick}>Services</Link>
                </li>
                <li>
                  <Link onClick={handleWeCareClick}>We Care</Link>
                </li>
                <li>
                  <Link onClick={handleContactClick}>Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="site-map">
              <h4>Work Days</h4>
              <ul className="list-unstyled schedule">
                <li>Mon - Sun</li>
                <li>09am - 05pm</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="w-100 float-left copyright-con position-relative">
        {isVisible && (
          <div className="scroll-to-top" onClick={scrollToTop}>
            <div className="black-btn">
              <img src="images/top-arrow.png" alt="top-arrow" />
            </div>
          </div>
        )}
        <div className="wrapper">
          <div className="copyright-inner-con d-flex align-items-center justify-content-between">
            <div className="footer-social">
              <ul className="list-unstyled mb-0 d-flex">
                <li>
                  <Link to="https://www.facebook.com/">
                    <i className="fab fa-facebook-f d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </i>
                  </Link>
                </li>
                <li>
                  <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2F%3Flang%3Den">
                    <i className="fab fa-twitter d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon icon={faTwitter} />
                    </i>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.linkedin.com/login">
                    <i className="fab fa-linkedin-in d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </i>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/">
                    <i className="fab fa-instagram d-flex align-items-center justify-content-center">
                      <FontAwesomeIcon icon={faInstagram} />
                    </i>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="copyright-txt">
              <span>©2023 LetsClean , All Rights Reserved. </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OfficeCleaning;
