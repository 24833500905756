import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./pages/componet/ScrollToTop";


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename={baseUrl}>
    <ScrollToTop />
    <App />
  </BrowserRouter>
);

reportWebVitals();
