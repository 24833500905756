import { Route, Routes } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./App.css";
import Home from "./pages/Home";
import Error from "./pages/Error";
import KitchenCleaning from "./pages/KitchenCleaning";
import OfficeCleaning from "./pages/OfficeCleaning";
import HouseCleaning from "./pages/HouseCleaning";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/HouseCleaning" element={<HouseCleaning />}></Route>
      <Route path="/OfficeCleaning" element={<OfficeCleaning />}></Route>
      <Route path="/KitchenCleaning" element={<KitchenCleaning />}></Route>
      <Route path="/*" element={<Error />}></Route>
      {/* "homepage": "https://tri-tap.com/cleaning/", */}
      {/* "homepage": "https://gmukacleaningltd.co.uk/", */}
      
    </Routes>
  );
}

export default App;
