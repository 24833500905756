import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Loader from "./componet/Loader";
import { Link } from "react-router-dom";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const options = {
  loop: false,
  margin: 38,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  navText: [
    "<img src='images/left-arrow.png' />",
    "<img src='images/right-arrow.png' />",
  ],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 3,
    },
    992: {
      items: 3,
    },
    1200: {
      items: 3,
    },
  },
};
function Home() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const backtopRef = useRef(null);
  const serviceRef = useRef(null);
  const aboutRef = useRef(null);
  const wecareRef = useRef(null);
  const contactRef = useRef(null);

  const handleBackTopClick = () => {
    if (backtopRef.current) {
      backtopRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleServiceClick = () => {
    if (serviceRef.current) {
      serviceRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleAboutClick = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleWeCareClick = () => {
    if (wecareRef.current) {
      wecareRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleContactClick = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const phoneNumber = "+447932926455";
  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const [formState, setFormState] = useState({});

  const changeHandler = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    let body =
      "Name : " +
      formState.fname +
      "<br/>Email : " +
      formState.email +
      "<br/>Location : " +
      formState.location +
      "<br/>Message : " +
      formState.message +
      "<br/>Phone : " +
      formState.phone;
    const config = {
      SecureToken: "00bef613-a761-4843-9f0e-db34653518c8",
      To: "makwanajaydip153@gmail.com",
      From: "jaydip.tritechno@gmail.com",
      Subject: "This is the my contact form",
      Body: body,
    };
    if (window.Email) {
      window.Email.send(config).then((message) =>
        alert("Email Send Succesfully !")
      );
    }
  };

  return (
    <>
      <div id="switcher-body">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <header
              className="w-100 float-left header-con"
              id="back-top"
              ref={backtopRef}
            >
              <div className="wrapper">
                <nav className="navbar navbar-expand-lg navbar-dark px-0">
                  <Link className="navbar-brand d-lg-none" to="/">
                    <img src="images/mobile-logo.png" alt="mobile-logo" />
                  </Link>

                  <button
                    className="navbar-toggler collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarToggle"
                    aria-controls="navbarToggle"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                    <span className="navbar-toggler-icon"></span>
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse justify-content-between"
                    id="navbarToggle"
                  >
                    <Link className="navbar-brand d-none d-lg-block" to="/">
                      <div className="mb-0">
                        <img
                          src="images/web-logo.png"
                          alt="logo-img"
                          className="logo-size"
                        />
                      </div>
                    </Link>
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link
                          activeClassName="active"
                          className="nav-link p-0"
                          to="/"
                          onClick={handleBackTopClick}
                        >
                          HOME
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link p-0"
                          onClick={handleAboutClick}
                        >
                          ABOUT US{" "}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          className="nav-link p-0"
                          onClick={handleServiceClick}
                        >
                          Services{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link p-0"
                          onClick={handleWeCareClick}
                        >
                          We Care{" "}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          className="nav-link p-0"
                          onClick={handleContactClick}
                        >
                          Contact{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </header>
            <section
              id="back-top"
              className="w-100 float-left banner-con home-banner position-relative"
            >
              <div className="banner-inner-con position-relative">
                <div className="wrapper2">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="banner-title position-relative">
                        <h1
                          className="text-black"
                          data-aos="fade-up"
                          data-aos-duration="600"
                        >
                          Affordable Cleaning Services For Everyone.
                        </h1>
                        <p
                          data-aos="fade-up"
                          data-aos-duration="600"
                          className="text-black"
                          style={{ color: "rgb(0 0 0 / 60%)" }}
                        >
                          Being a company that prioritizes its customers, our
                          primary goal is to provide exceptional customer
                          service and satisfaction through our efficient and
                          skilled provision of End of Tenancy Cleaning, Move out
                          Cleaning, and Carpet Cleaning Services in London and
                          its environs. Our seasoned team possesses the
                          necessary expertise and experience to handle a wide
                          range of tasks effectively.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-5 order-lg-0 order-1">
                      <div className="banner-img">
                        <figure className="mb-0">
                          <img src="images/img1.png" alt="home-girls-img" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="w-100 float-left clean-con">
              <div className="wrapper2">
                <div className="generic-box">
                  <div
                    className="generic-box-img position-relative circle-box"
                    data-aos="fade-up"
                    data-aos-duration="600"
                  >
                    <div className="generic-box-img1 position-relative">
                      <figure className="mb-0 position-relative">
                        <img src="images/image2.png" alt="clean-img" />
                        {/* <img src="images/clean-img.png" alt="clean-img" /> */}
                      </figure>
                    </div>
                    <figure className="mb-0 generic-box-img2">
                      <img src="images/image1.jpg" alt="clean-small-img" />
                    </figure>
                  </div>
                  <div className="generic-box-content position-relative">
                    <h2 data-aos="fade-up" data-aos-duration="600">
                      We will make absolutely any{" "}
                      <span className="color-01d0cc">
                        place clean, neat &amp; tidy.
                      </span>
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="600">
                      At G-muka cleaning Ltd Company, we believe that a clean
                      environment leads to a happier and healthier life. That's
                      why we tailor our cleaning services to meet the unique
                      needs of each client. Whether it's residential,
                      commercial, or industrial cleaning, we understand that
                      every space is different. We work closely with our clients
                      to create custom cleaning solutions that work for them.
                    </p>
                    <div className="generic-list">
                      <ul className="list-unstyled">
                        <li data-aos="fade-up" data-aos-duration="600">
                          <span className="d-block">We Are Committed</span>
                          <p className="mb-0">
                            We recognize that every space is unique, whether
                            it's a home, office, or facility. That's why we work
                            closely with our clients to create tailored cleaning
                            solutions that meet their specific needs. Our
                            commitment is to understand your requirements and
                            deliver cleaning services that exceed your
                            expectations.
                          </p>
                        </li>
                        <li data-aos="fade-up" data-aos-duration="600">
                          <span className="d-block">
                            Regular &amp; Monthly Cleaning
                          </span>
                          <p className="mb-0">
                            In the business world, a clean and organized
                            workspace fosters productivity. Our regular cleaning
                            services are tailored to meet the unique needs of
                            your commercial or space. Whether it's an office,
                            retail establishment, or facility, we ensure that
                            your workspace is consistently clean, creating an
                            environment where employees can thrive and clients
                            feel welcomed.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="w-100 float-left service-con position-relative"
              id="service"
              ref={serviceRef}
            >
              <div className=" position-relative">
                <div className="wrapper">
                  <div className="service-title text-center position-relative">
                    <h2
                      className="text-black"
                      data-aos="fade-up"
                      data-aos-duration="600"
                    >
                      Our most popular{" "}
                      <span className="color-01d0cc d-block">
                        cleaning services for you
                      </span>
                    </h2>
                  </div>
                  <div data-aos="fade-up" data-aos-duration="600">
                    <OwlCarousel {...options} className="owl-theme" margin={10}>
                      <div className="item">
                        <div className="service-item text-center">
                          <figure>
                            <img
                              src="images/house-cleaning.jpg"
                              alt="service-img"
                            />
                          </figure>
                          <h3>House Cleaning</h3>
                          <p>
                            We believe in the simple yet profound truth that a
                            clean home is a happy home. When your living space
                            is free from dirt, dust, and clutter, you experience
                            a sense of calm, comfort, and overall well-being.
                            Our house cleaning services are designed to create a
                            haven for you and your family, allowing you to enjoy
                            life's moments without the worry of household
                            chores.
                          </p>
                          <Link to="/HouseCleaning">
                            <img
                              src="images/left-angle.png"
                              alt="left-angle"
                              className="w-25"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="item">
                        <div className="service-item text-center">
                          <figure>
                            <img
                              src="images/office-cleaning.jpg"
                              alt="service-img"
                            />
                          </figure>
                          <h3>Office Cleaning</h3>
                          <p>
                            A clean office is more than just an aesthetic
                            choice; it's a strategic one. It promotes a
                            healthier work environment, reduces sick days, and
                            fosters a positive atmosphere that can boost
                            employee morale and productivity. A clean office is
                            also crucial for making a positive impression on
                            clients and visitors.
                          </p>
                          <Link to="/OfficeCleaning">
                            <img
                              src="images/left-angle.png"
                              alt="left-angle"
                              className="w-25"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="item">
                        <div className="service-item text-center">
                          <figure>
                            <img
                              src="images/kitchen-cleaning.jpg"
                              alt="service-img"
                            />
                          </figure>
                          <h3>Kitchen Cleaning</h3>
                          <p>
                            A clean kitchen is more than just a matter of
                            aesthetics; it's a matter of health and safety.
                            Kitchens can be hotspots for germs and bacteria,
                            making it essential to maintain a high level of
                            cleanliness. A clean kitchen not only safeguards
                            your family's well-being but also enhances your
                            culinary experience.
                          </p>
                          <Link to="/KitchenCleaning">
                            <img
                              src="images/left-angle.png"
                              alt="left-angle"
                              className="w-25"
                            />
                          </Link>
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="w-100 float-left bg-fafafa service-con position-relative"
              id="about-us"
              ref={aboutRef}
            >
              <div className=" position-relative">
                <div className="wrapper">
                  <div className="service-title text-center position-relative">
                    <h2
                      className="text-black"
                      data-aos="fade-up"
                      data-aos-duration="600"
                    >
                      About <span className="color-01d0cc ">Us</span>
                    </h2>
                  </div>
                  <div className="goals-box text-center  position-relative">
                    <div
                      className="goals-box-item rounded-3 "
                      style={{ borderRadius: "5%" }}
                    >
                      <figure
                        className="d-flex align-items-center justify-content-center mx-auto"
                        data-aos="fade-up"
                        data-aos-duration="600"
                      >
                        <img src="images/mission-icon.png" alt="mission-icon" />
                      </figure>
                      <h2 data-aos="fade-up" data-aos-duration="600">
                        Our Mission
                      </h2>
                      <p
                        className="mb-0 text-justify"
                        data-aos="fade-up"
                        data-aos-duration="600"
                      >
                        At G-muka cleaning Ltd Company, our mission is clear and
                        unwavering: we are committed to elevating cleanliness to
                        its highest standard, one space at a time, and in doing
                        so, we aim to transform lives. We believe that
                        cleanliness is not just about aesthetics; it's the
                        foundation of health, well-being, and peace of mind. Our
                        mission is to redefine the standard of cleanliness,
                        going above and beyond to create spaces that are not
                        only spotless but also safe, healthy, and refreshing. A
                        clean environment has the power to transform lives. It
                        reduces stress, enhances productivity, and fosters
                        happiness. By delivering exceptional cleaning services,
                        we seek to make a positive impact on the lives of our
                        clients, providing them with the freedom to enjoy their
                        surroundings to the fullest.
                      </p>
                    </div>
                    <div
                      className="goals-box-item"
                      style={{ borderRadius: "5%" }}
                    >
                      <figure
                        className="d-flex align-items-center justify-content-center mx-auto"
                        data-aos="fade-up"
                        data-aos-duration="600"
                      >
                        <img src="images/vision-icon.png" alt="vision-icon" />
                      </figure>
                      <h2 data-aos="fade-up" data-aos-duration="600">
                        Our Vision
                      </h2>
                      <p
                        className="mb-0 text-justify"
                        data-aos="fade-up"
                        data-aos-duration="600"
                      >
                        At G-muka cleaning Ltd Company, our vision extends far
                        beyond today. We aspire to be pioneers in creating a
                        cleaner, safer, and more sustainable tomorrow for our
                        clients, our community, and the world. We envision a
                        future where cleanliness is synonymous with excellence.
                        We aim to lead the cleaning industry by setting the
                        highest standards for quality, innovation, and
                        environmental responsibility. We aim to set the industry
                        standard for excellence in cleaning services. Our vision
                        is to consistently deliver spotless, sanitized, and
                        well-organized spaces, leaving a lasting impression of
                        quality and attention to detail. We believe in staying
                        ahead of the curve by embracing innovative cleaning
                        methods, technologies, and eco-friendly practices. Our
                        vision is to continually evolve, adapting to the
                        changing needs and preferences of our clients while
                        reducing our environmental footprint.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="w-100 float-left project-con  service-dots position-relative"
              id="we-care"
              ref={wecareRef}
            >
              <div className="wrapper2">
                <h2
                  data-aos="fade-up"
                  style={{ color: "var(--primary--color)" }}
                  data-aos-duration={600}
                  className="text-center"
                >
                  We Care
                </h2>
                <div className="generic-box service-box2 position-relative">
                  <div className="generic-box-content position-relative">
                    <div className="generic-list2">
                      <ul className="list-unstyled mb-0">
                        <span
                          className="job-title"
                          data-aos="fade-up"
                          data-aos-duration={600}
                        >
                          Quality :
                        </span>{" "}
                        <li data-aos="fade-up" data-aos-duration={600}>
                          We are dedicated to delivering top-quality cleaning
                          services, ensuring that every space we touch is
                          thoroughly cleaned, sanitized, and revitalized.
                        </li>
                        <span
                          className="job-title"
                          data-aos="fade-up"
                          data-aos-duration={600}
                        >
                          Customization :
                        </span>{" "}
                        <li data-aos="fade-up" data-aos-duration={600}>
                          We understand that each space is unique, and we tailor
                          our cleaning solutions to meet the specific needs of
                          our clients.
                        </li>
                        <span
                          className="job-title"
                          data-aos="fade-up"
                          data-aos-duration={600}
                        >
                          Environmental Responsibility :
                        </span>{" "}
                        <li data-aos="fade-up" data-aos-duration={600}>
                          We use eco-friendly products and sustainable practices
                          to protect both our clients' health and the planet.
                        </li>
                        <span
                          className="job-title"
                          data-aos="fade-up"
                          data-aos-duration={600}
                        >
                          Professionalism :
                        </span>{" "}
                        <li data-aos="fade-up" data-aos-duration={600}>
                          We approach every project with the highest level of
                          professionalism, treating our clients' spaces with
                          respect and care.
                        </li>
                        <span
                          className="job-title"
                          data-aos="fade-up"
                          data-aos-duration={600}
                        >
                          Trustworthiness :
                        </span>{" "}
                        <li data-aos="fade-up" data-aos-duration={600}>
                          We aim to be a trustworthy partner, consistently
                          delivering on our promises and building long-lasting
                          relationships with our clients.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="generic-box-img">
                    <div
                      className="generic-box-img1 position-relative"
                      data-aos="fade-up"
                      data-aos-duration={600}
                    >
                      <figure className="mb-0">
                        <img src="images/image3.jpg" alt="clean-img" />
                        {/* <img
                          src="images/service-banner-img.png"
                          alt="clean-img"
                        /> */}
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="w-100 float-left bg-fafafa clean-con">
              <div className="wrapper2">
                <div className="generic-box service-box position-relative">
                  <div
                    className="generic-box-img"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      src="images/tomorrow.png"
                      alt="clean-img"
                      style={{ width: "80%" }}
                    />

                    {/* <div
                      className="generic-box-img1 generic-box-img12 position-relative"
                      data-aos="fade-up"
                      data-aos-duration={600}
                    >
                      <figure className="mb-0">
                        <img src="images/tomorrow.png" alt="clean-img" />
                      </figure>
                    </div> */}
                  </div>
                  <div className="generic-box-content position-relative">
                    <h2
                      data-aos="fade-up"
                      style={{ color: "var(--primary--color)" }}
                      data-aos-duration={600}
                    >
                      A Safer Tomorrow
                    </h2>
                    <p data-aos="fade-up" data-aos-duration={600}>
                      Cleanliness isn't just about appearances; it's about
                      health and safety. We envision a world where clean
                      environments contribute to the well-being and security of
                      all who inhabit them. Through our services, we seek to
                      prevent the spread of illness and promote overall health.
                    </p>
                    <p data-aos="fade-up" data-aos-duration={600}>
                      Regular cleaning and disinfecting can do a good job of
                      removing allergens and germs, helping to prevent illnesses
                      and promote wellness.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="w-100 float-left contact-con position-relative"
              id="Contact"
              ref={contactRef}
            >
              <div className="contact-inner-con position-relative">
                <div className="wrapper">
                  <div className="row">
                    <div className="col-lg-7 col-md-7 order-md-0 order-2">
                      <div className="contact-title position-relative">
                        <h2
                          className="text-black"
                          data-aos="fade-up"
                          data-aos-duration="600"
                        >
                          Get a free estimate
                        </h2>
                      </div>
                      <form
                        className="form-con"
                        data-aos="fade-up"
                        data-aos-duration="600"
                        // method="post"
                        // id="contactpage"
                        onSubmit={submitHandler}
                      >
                        <ul className="list-unstyled">
                          <li>
                            <input
                              type="text"
                              placeholder="Your Name"
                              name="fname"
                              value={formState.fname || ""}
                              onChange={changeHandler}
                            />
                          </li>
                          <li>
                            <input
                              type="tel"
                              placeholder="Phone Number"
                              name="phone"
                              value={formState.phone || ""}
                              onChange={changeHandler}
                            />
                          </li>
                          <li>
                            <input
                              type="email"
                              placeholder="Email Address"
                              name="email"
                              value={formState.email || ""}
                              onChange={changeHandler}
                            />
                          </li>
                          <li>
                            <input
                              type="text"
                              placeholder="Location"
                              name="location"
                              value={formState.location || ""}
                              onChange={changeHandler}
                            />
                          </li>
                          <li>
                            <textarea
                              placeholder="Your Message"
                              name="message"
                              value={formState.message || ""}
                              onChange={changeHandler}
                            ></textarea>
                          </li>
                        </ul>
                        <input
                          className="submit-btn"
                          type="submit"
                          value="Send Now"
                        />
                      </form>
                    </div>
                    <div
                      className="col-lg-5 col-md-5 order-md-0 order-1"
                      data-aos="fade-up"
                      data-aos-duration="600"
                    >
                      <figure className="mb-0">
                        <img src="images/estimate.png" alt="contact-img" />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="w-100 float-left bg-fafafa clean-con buttom-con">
              <div className="container">
                <div
                  className="w-100 float-left map-con position-relative aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3290.8916487673278!2d-58.58502042334938!3d-34.42950724832019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bca541965d56db%3A0xcdd42a54563afcf5!2sRio%20Tigris%20Apart%201!5e0!3m2!1sen!2s!4v1690809389702!5m2!1sen!2s"
                    title="Important Information"
                    style={{ borderRadius: "3%" }}
                  ></iframe>
                </div>
              </div>
            </section>
            <footer className="w-100 float-left footer-con position-relative">
              <div className="wrapper">
                <div className="footer-box position-relative">
                  <div className="site-map">
                    <h4>About Us</h4>
                    <p className="">
                      We are dedicated to delivering top-quality cleaning
                      services, ensuring that every space we touch is thoroughly
                      cleaned, sanitized, and revitalized.
                    </p>
                    <p>G-muka cleaning Ltd</p>
                    <div class="footer-phone">
                      <a
                        href={`tel:${phoneNumber}`}
                        style={{ fontSize: "20px" }}
                        onClick={handleCallClick}
                      >
                        +44 - 7932 926455
                      </a>
                    </div>
                    {/* <p>+44 - 7932 926455</p> */}
                  </div>
                  <div className="site-map">
                    <h4>Quick Links</h4>
                    <ul className="list-unstyled mb-0">
                      <li>
                        <Link onClick={handleBackTopClick}>Home</Link>
                      </li>
                      <li>
                        <Link onClick={handleAboutClick}>About us</Link>
                      </li>
                      <li>
                        <Link onClick={handleServiceClick}>Services</Link>
                      </li>
                      <li>
                        <Link onClick={handleWeCareClick}>We Care</Link>
                      </li>
                      <li>
                        <Link onClick={handleContactClick}>Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="site-map">
                    <h4>Work Days</h4>
                    <ul className="list-unstyled schedule">
                      <li>Mon - Sun</li>
                      <li>09am - 05pm</li>
                    </ul>
                  </div>
                </div>
              </div>
            </footer>
            <div className="w-100 float-left copyright-con position-relative">
              {isVisible && (
                <div className="scroll-to-top" onClick={scrollToTop}>
                  <div className="black-btn">
                    <img src="images/top-arrow.png" alt="top-arrow" />
                  </div>
                </div>
              )}
              <div className="wrapper">
                <div className="copyright-inner-con d-flex align-items-center justify-content-between">
                  <div className="footer-social">
                    <ul className="list-unstyled mb-0 d-flex">
                      <li>
                        <Link to="https://www.facebook.com/">
                          <i className="fab fa-facebook-f d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faFacebookF} />
                          </i>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2F%3Flang%3Den">
                          <i className="fab fa-twitter d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faTwitter} />
                          </i>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.linkedin.com/login">
                          <i className="fab fa-linkedin-in d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                          </i>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.instagram.com/">
                          <i className="fab fa-instagram d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faInstagram} />
                          </i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="copyright-txt">
                    <span>©2023 LetsClean , All Rights Reserved. </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Home;
